<template>
  <v-card>
    <v-card-title>Animals Involved in Incidents</v-card-title>
    <!-- <v-card-text>Explanation of the graph?</v-card-text> -->
    <v-card-text class="text-center">
      <bar-chart v-if="animalsloaded" :chartData="chartdata" :options="options" />
      <template v-else >
        <p>Loading data from server...</p>
        <v-progress-circular indeterminate size="100"/>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import { get } from "vuex-pathify";
import BarChart from "@/charts/BarChart.vue"

export default {
  name: "Animals",
  components: { BarChart },
  data: function () {
    return {
      counter: 1,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true,
            type: 'logarithmic'
          }]
        }
      },
      datacollection: null,
      chartdata: {
          labels: [],
          datasets: []
      }
    };
  },
  async mounted() {
    await new Promise((resolve) => setTimeout(resolve, 500));
    this.fillData();
  },
  computed: {
    ...get("chart", ["animals", "animalsloaded"]),
  },
  methods: {
    fillData() {
      this.chartdata = {
         labels: this.animals.labels,
        datasets: [
            {
              label: "Dogs",
              data: this.animals.dataset[0].data,
              // data: dataset.dataset[0].data,
              backgroundColor: "#0CAC5B"
            },
            {
              label: "Cats",
              data: this.animals.dataset[1].data,
              // data: this.animals(this.country).dataset[1].data,
              // data: dataset.dataset[1].data,
              backgroundColor: "#0CAC5B"
            },
            {
              label: "Wild",
              data: this.animals.dataset[2].data,
              // data: this.animals(this.country).dataset[2].data,
              // data: dataset.dataset[2].data,
              backgroundColor: "#ED7A40"
            },
            {
              label: "Birds",
              data: this.animals.dataset[3].data,
              // data: this.animals(this.country).dataset[3].data,
              // data: dataset.dataset[3].data,
              backgroundColor: "#FCBE3B"
            }
        ] 
      }
    },
  },
};
</script>
