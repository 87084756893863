<script>
// import { Bar, mixins } from "vue-chartjs";
// const { reactiveProp } = mixins;

// export default {
//   extends: Bar,
//   mixins: [reactiveProp],
// //   data() {
// //     return {
// //       options: {
// //         responsive: true,
// //         maintainAspectRatio: false,
// //       },
// //     };
// //   },
//   mounted() {
//     this.renderChart(this.chartData, this.options);
//   },
// }


import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
    extends: Bar,
    mixins: [reactiveProp],
    props: {
        chartData: {
            type: Object,
            default: () => ({})
        },
        options: {
            type: Object,
            default: () => ({
                responsive: true,
                maintainAspectRatio: false
            })
        }
    },
    mounted () {
        this.renderChart(this.chartData, this.options) // maybe typo?
    },
    methods: {
        resize() {
            this.$data._chart.resize()
        }
    },
    watch: {
        chartData () {
            this.$data._chart.update()
        }
    }
}
</script>