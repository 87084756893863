<template>
  <v-card>
    <v-card-title>Toxins used in Incidents</v-card-title>
    <!-- <v-card-text>Explanation of the graph?</v-card-text> -->
    <v-card-text class="text-center">
      <doughnut-chart v-if="toxinsloaded" :chartData="chartdata" :options="options" />
      <template v-else >
        <p>Loading data from server...</p>
        <v-progress-circular indeterminate size="100"/>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import { get } from "vuex-pathify";
import DoughnutChart from "@/charts/DoughnutChart.vue"

function stringToHash(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++ ) {
        hash = (hash << 5) - hash + str.charCodeAt(i);
        hash = hash & hash;
    }
    return hash;
}

// Convert a hash value to an RGB color.
function hashToRgb(hash) {
    const r = (hash & 0xFF0000) >> 16;
    const g = (hash & 0x00FF00) >> 8;
    const b = hash & 0x0000FF;
    return `rgb(${r}, ${g}, ${b})`;
}

// Create a color based on a string (label)
function labelToColor(label) {
    const hash = stringToHash(label);
    return hashToRgb(hash)
}

export default {
  name: "Toxins",
  components: { DoughnutChart },
  data: function () {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        // scales: {
        //   xAxes: [{
        //     stacked: true
        //   }],
        //   yAxes: [{
        //     stacked: true
        //   }]
        // }
      },
      chartdata: {
        labels: [],
        datasets: [],
      },
    };
  },
  async mounted() {
    await new Promise((resolve) => setTimeout(resolve, 400));
    this.fillData();
  },
  computed: {
    ...get("chart", ["toxins", "toxinsloaded"]),
  },
  methods: {
    fillData() {
      const backgroundColors = this.toxins.labels.map(label => labelToColor(label))
      this.chartdata = {
        type: 'doughnut',
        labels: this.toxins.labels,
        datasets: [
          {
            label: 'check what is this?',
            data: this.toxins.series,
            // no - unknown - yes
            // backgroundColor: [ '#404564','#dedfe4','#28afbb']
            backgroundColor: backgroundColors
          },
        ] 
      }
    },
  },
};
</script>
