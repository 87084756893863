<template>
  <v-card width="100%">
    <!-- <v-card-title class="text-h5 mb-2 justify-center" style="background-color: #d9c7A3"
      ><v-spacer />
      {{ $t('statistics.title') }}
      <v-spacer /> <v-btn icon @click.stop="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title
    > -->
    <v-toolbar color="#d9c7A3" class="mb-2">
      <v-spacer />
      <v-toolbar-title>   
        {{ $t('statistics.title') }}
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon @click.stop="close"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-card-text>
      
      <p>
        Let's see the incidents in graphs
      </p>
      <!-- <incidents-per-year /> -->
      <!-- <random-chart /> -->
      <!-- <p>{{ incidents }}</p> -->
      <!-- <barchart v-if="yearloaded" :chartdata="incidents" :options="chartOptions" /> -->
      <!-- <v-divider /> -->
      <!-- <p>{{ permonth }}</p> -->
      <!-- <barchart v-if="monthloaded" :chartdata="permonth" :options="monthOptions" /> -->

      <!-- IMPLEMENTATION WITH V-FLEX -->
      <!-- <v-container py-0>
        <v-layout wrap>
          <v-flex xs12 md12 class="pa-2">
            <incidents-per-year />
          </v-flex>
          <v-flex xs12 md12 class="pa-2">
            <incidents-per-month/>
          </v-flex>
          <v-flex xs12 md4 class="pa-2">
            <official-complaints />
          </v-flex>
          <v-flex xs12 md4 class="pa-2">
            <cases-to-court />
          </v-flex>
          <v-flex xs12 md4 class="pa-2">
            <autopsy />
          </v-flex>
        </v-layout>
      </v-container> -->

      <!-- IMPLEMENTATION WITH v-row/v-col -->
      <v-container py-0>
        <v-row>
          <v-col md="12">
            <incidents-per-year />
          </v-col>
          <v-col md="12">
            <incidents-per-month/>
          </v-col>
          <v-col md="12">
            <animals />
          </v-col>
          <v-col md="4" xs="12">
            <official-complaints />
          </v-col>
          <v-col md="4" xs="12">
            <cases-to-court />
          </v-col>
          <v-col md="4" xs="12">
            <autopsy />
          </v-col>
          <v-col md="12">
            <toxins />
          </v-col>
        </v-row>
      </v-container>
      
    </v-card-text>
  </v-card>
</template>

<script>
// import IncidentsPerYear from '../charts/IncidentsPerYear.vue';
// import RandomChart from '@/components/charts/RandomChart.vue'
import {get} from 'vuex-pathify'
// import Barchart from '@/components/charts/Barchart.vue';
import IncidentsPerMonth from '../charts/IncidentsPerMonth.vue';
import IncidentsPerYear from '../charts/IncidentsPerYear.vue';
import OfficialComplaints from '../charts/OfficialComplaints.vue'
import CasesToCourt from '../charts/CasesToCourt.vue'
import Autopsy from '../charts/Autopsy.vue'
import Animals from '../charts/Animals.vue'
import Toxins from '../charts/Toxins.vue'


export default {
  // components: { IncidentsPerYear },
  components: { 
    IncidentsPerMonth,
    IncidentsPerYear, 
    OfficialComplaints,
    CasesToCourt,
    Autopsy,
    Animals,
    Toxins
  },
  name: "Statistics",
  data: () => ({
    // yearloaded: false,
    // monthloaded: false,
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          stacked: true
        }],
        yAxes: [{
          stacked: true
        }]
      }
    },
    monthOptions: {
      responsive: true,
      maintainAspectRatio: false,
    }
  }),
  methods: {
    close () {
      this.$emit("closedialog", false)
    }
  },
  // async mounted () {
  //   this.loaded = false
  //   try {
  //     await this.$store.dispatch('chart/fetchall')
  //     this.loaded = true
  //   } catch (e) {
  //     console.error(e)
  //   }
  // },
  computed: {
    ...get('chart', [
      'incidentperyear',
      'incpermonth',
      'yearloaded',
      'monthloaded'
    ]),
    incidents () {
      if (this.incidentperyear) {
        return {
          labels: this.incidentperyear.labels,
          datasets: [
            {
              label: "Incidents",
              backgroundColor: "#f87979",
              data: this.incidentperyear.series[0]
            },
            {
              label: "Antipoison dog",
              backgroundColor: "green",
              data: this.incidentperyear.series[1]
            }
          ]
        }
      } else {
        return {}
      }
    },
    // permonth () {
    //   if (this.incpermonth) {
    //     return {
    //       labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    //       datasets: [
    //         {
    //           label: "Incidents",
    //           backgroundColor: "blue",
    //           data: this.incpermonth.series
    //         }
    //       ]
    //     }
    //   } else {
    //     return {}
    //   }
    // }
  },
};
</script>