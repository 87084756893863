<template>
  <v-card>
    <v-card-title>Cases To Court</v-card-title>
    <!-- <v-card-text>Explanation of the graph?</v-card-text> -->
    <v-card-text class="text-center">
      <doughnut-chart v-if="courtloaded" :chartData="chartdata" :options="options" />
      <template v-else >
        <p>Loading data from server...</p>
        <v-progress-circular indeterminate size="100"/>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import { get } from "vuex-pathify";
import DoughnutChart from "@/charts/DoughnutChart.vue"

export default {
  name: "CasesToCourt",
  components: { DoughnutChart },
  data: function () {
    return {
      boo: false,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        // scales: {
        //   xAxes: [{
        //     stacked: true
        //   }],
        //   yAxes: [{
        //     stacked: true
        //   }]
        // }
      },
      chartdata: {
        labels: [],
        datasets: [],
      },
    };
  },
  async mounted() {
    await new Promise((resolve) => setTimeout(resolve, 400));
    this.fillData();
  },
  computed: {
    ...get("chart", ["court", "courtloaded"]),
  },
  methods: {
    fillData() {
      this.chartdata = {
        type: 'doughnut',
        labels: this.court.labels,
        datasets: [
          {
            label: 'check what is this?',
            data: this.court.series,
            // no - not applicable - unknown - yes
            backgroundColor: [ '#404564','red','#dedfe4', '#28afbb']
          },
        ] 
      }
    },
  },
};
</script>
