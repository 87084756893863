<template>
  <v-card>
    <v-card-title>Incidents per YEAR</v-card-title>
    <!-- <v-card-text>Explanation of the graph?</v-card-text> -->
    <v-card-text class="text-center">
      <bar-chart v-if="yearloaded" :chartData="chartdata" :options="options" />
      <template v-else >
        <p>Loading data from server...</p>
        <v-progress-circular indeterminate size="100"/>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import { get } from "vuex-pathify";
import BarChart from "@/charts/BarChart.vue"

export default {
  name: "IncidentsPerYear",
  components: { BarChart },
  data: function () {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true
          }]
        }
      },
      chartdata: {
        labels: [],
        datasets: [],
      },
    };
  },
  async mounted() {
    await new Promise((resolve) => setTimeout(resolve, 400));
    this.fillData();
  },
  computed: {
    ...get("chart", ["incidentperyear", "yearloaded"]),
  },
  methods: {
    fillData() {
      this.chartdata = {
        labels: this.incidentperyear.labels,
        datasets: [
          {
            label: "Incidents",
            backgroundColor: "#056dac",
            data: this.incidentperyear.series[0]
          },
          {
            label: "Antipoison dog",
            backgroundColor: "#28afbb",
            data: this.incidentperyear.series[1]
          }
        ] 
      }
    },
  },
};
</script>
