<template>
  <v-card>
    <v-card-title>Incidents per month</v-card-title>
    <!-- <v-card-text>Explanation of the graph?</v-card-text> -->
    <v-card-text class="text-center">
      <bar-chart v-if="monthloaded" :chartData="chartdata" :options="options" />
      <template v-else >
        <p>Loading data from server...</p>
        <v-progress-circular indeterminate size="100"/>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import { get } from "vuex-pathify";
import BarChart from "@/charts/BarChart.vue"

export default {
  name: "IncidentsPerMonth",
  components: { BarChart },
  data: function () {
    return {
      counter: 1,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true
          }]
        }
      },
      datacollection: null,
      chartdata: {
          labels: [],
          datasets: []
      }
    };
  },
  async mounted() {
    await new Promise((resolve) => setTimeout(resolve, 500));
    this.fillData();
  },
  computed: {
    ...get("chart", ["incpermonth", "monthloaded"]),
  },
  methods: {
    fillData() {
      this.chartdata = {
         labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "Incidents",
            backgroundColor: "#056dac",
            data: this.incpermonth.series[0],
          },
          {
            label: "Antipoison dog",
            backgroundColor: "#28afbb",
            data: this.incpermonth.series[1]
          }
        ] 
      }
    },
  },
};
</script>
